<template>
  <v-hover>
    <v-card
      color="primary"
      slot-scope="{ hover }"
      class="grow"
      :elevation="`${hover ? 18 : 2}`"
    >
      <v-card-title
        primary-title
        class="justify-center"
      >
        <h5 class="headline white--text">
          {{ title }}
        </h5>
      </v-card-title>
      <v-card-text class="white--text">
        <p>{{ text }}</p>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>

</style>
