<template>
  <v-container grid-list-sm>
    <v-layout
      justify-space-around
      wrap
    >
      <template v-if="!showStepper">
        <v-flex
          xs4
          v-for="(item, ind) in bigCards"
          :key="ind"
        >
          <grow-card
            role="button"
            :title="item.title"
            :text="item.text"
            @click.native="openStepper(item.type)"
            :data-cy="'new-reservation-' + item.type"
          />
        </v-flex>
      </template>
      <v-flex xs12>
        <reservation-stepper
          v-if="showStepper"
          :type="reservationType"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/* This component serves as the form where users can fill out their reservation upon confirmation of times */
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import ReservationStepper from './ReservationStepper'
import GrowCard from './GrowCard'
export default {
  components: {
    ReservationStepper,
    GrowCard
  },
  data () {
    return {
      showStepper: false,
      reservationType: 'new',
      bigCards: [
        {
          type: 'new',
          title: 'Create New',
          text: 'Choose this option if you would like to create a new reservation from scratch.'
        },
        {
          type: 'existing',
          title: 'Create From Existing',
          text: 'Choose this option if you would like to use a previous reservation as a starting point.'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getFullName'
    ]),
    ...mapState({
      isUserAdmin: state => state.isUserAdmin
    })
  },
  methods: {
    ...mapMutations('reservation', [
      'setReservationField',
      'setTour',
      'setHold'
    ]),
    ...mapActions('reservation/transactions', [
      'getContactAction',
      'getEquipmentAction'
    ]),
    openStepper (type) {
      if (type === 'new') {
        this.setTour(false)
        this.setHold(false)
      } else if (type === 'tour') {
        this.setTour(true)
        this.setHold(false)
      } else if (type === 'hold') {
        this.setTour(false)
        this.setHold(true)
      }
      this.reservationType = type
      this.showStepper = true
    }
  },
  mounted () {
    this.getContactAction()
    this.getEquipmentAction()
    if (this.isUserAdmin) {
      this.bigCards.push(
        {
          type: 'tour',
          title: 'Create Tour',
          text: 'Choose this option if you would like to create a new tour from scratch.'
        },
        {
          type: 'hold',
          title: 'Create Hold',
          text: 'Choose this option if you would like to create a new hold from scratch.'
        }
      )
    }
  }
}
</script>

<style>
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }
</style>
